const base = {
    login_path:'/dy_api/login',
    bd_chat: '/dy_api/bd-chat-single',
    azure_chat: '/dy_api/single-chat-process-a',
    yd_pic_translation: '/dy_api/yd-pic-content',
    yd_textranslation: '/dy_api/text-translation',
    bd_textTranslation: '/dy_api/bd-text-translation',
    bd_chat_single:'/dy_api/bd-chat-single',
    ali_pics_translation: '/dy_api/ali-pic-translate-direct',
    register:'/dy_api/register',
    sendEmail:'/dy_api/send-verification-code',
    forgetPwd: '/dy_api/forgot-password',
    resetPwd:'/dy_api/change-password',
    create_charge_qr_code:'/dy_api/create-charge',
    query_trade_result:'/dy_api/query-trade-package-duration',
    get_user_login_status_path:'/dy_api/get_user_login_status',
}

export default base